import { DropdownMenu as KDropdownMenu } from '@kobalte/core/dropdown-menu';
import { Icon } from '@troon/icons';
import { twMerge } from '@troon/tailwind-preset/merge';
import { Button } from '../forms';
import type { ComponentProps } from 'solid-js';

export function DropdownMenu(props: ComponentProps<typeof KDropdownMenu>) {
	return (
		<KDropdownMenu sameWidth fitViewport slide gutter={4} {...props}>
			{props.children}
		</KDropdownMenu>
	);
}

export function DropdownMenuTrigger(
	props: ComponentProps<(typeof KDropdownMenu)['Trigger']> & ComponentProps<typeof Button>,
) {
	return <KDropdownMenu.Trigger appearance="transparent" size="sm" {...props} as={Button} />;
}

export function DropdownMenuContent(props: ComponentProps<(typeof KDropdownMenu)['Content']>) {
	return (
		<KDropdownMenu.Portal>
			<KDropdownMenu.Content
				{...props}
				class="group/menu z-50 flex flex-col gap-1 rounded border border-neutral bg-white px-1.5 py-2 shadow-lg outline-none animate-out fade-out slide-out-to-top-3 anim-duration-100 ui-expanded:animate-in ui-expanded:slide-in-from-top-3"
			>
				{props.children}
			</KDropdownMenu.Content>
		</KDropdownMenu.Portal>
	);
}

export function DropdownMenuItem(props: ComponentProps<(typeof KDropdownMenu)['Item']>) {
	return (
		<KDropdownMenu.Item
			{...props}
			class={twMerge(
				'group relative flex cursor-pointer flex-row items-center gap-2 overflow-hidden rounded-md bg-white py-1 pe-4 ps-6 text-neutral-950 outline-none transition-colors duration-200 hover:bg-brand-100 aria-current-page:bg-brand-100 ui-highlighted:bg-brand-100/50',
				props.class,
			)}
		/>
	);
}

export function DropdownMenuItemIndicator(props: ComponentProps<(typeof KDropdownMenu)['ItemIndicator']>) {
	return <KDropdownMenu.ItemIndicator {...props} />;
}

export function DropdownMenuRadioGroup(props: ComponentProps<(typeof KDropdownMenu)['RadioGroup']>) {
	return <KDropdownMenu.RadioGroup {...props} class="flex flex-col" />;
}

export function DropdownMenuRadioItem(props: ComponentProps<(typeof KDropdownMenu)['RadioItem']>) {
	return (
		<KDropdownMenu.RadioItem
			{...props}
			class="group relative flex cursor-pointer flex-row items-center gap-2 overflow-hidden rounded-md bg-white py-1 pe-4 ps-6 text-neutral-950 outline-none transition-colors duration-200 hover:bg-brand-100 aria-current-page:bg-brand-100 ui-highlighted:bg-brand-100/50"
		>
			<KDropdownMenu.ItemIndicator class="absolute start-0 w-5 text-center text-2xl">
				&bull;
			</KDropdownMenu.ItemIndicator>
			{props.children}
		</KDropdownMenu.RadioItem>
	);
}

export function DropdownMenuCheckboxItem(props: ComponentProps<(typeof KDropdownMenu)['CheckboxItem']>) {
	return (
		<KDropdownMenu.CheckboxItem
			{...props}
			class="group relative flex cursor-pointer flex-row items-center gap-2 overflow-hidden rounded-md bg-white py-1 pe-4 ps-6 text-neutral-950 outline-none transition-colors duration-200 hover:bg-brand-100 aria-current-page:bg-brand-100 ui-highlighted:bg-brand-100/50"
		>
			<KDropdownMenu.ItemIndicator class="absolute start-0 w-5 text-center text-base">
				<Icon name="check" />
			</KDropdownMenu.ItemIndicator>
			{props.children}
		</KDropdownMenu.CheckboxItem>
	);
}
